.domain-masking-wrapper {
   display: flex;
   max-width: 1400px;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }

      button {
         width: 142px;
         height: 40px;
      }
   }

   .loader {
      display: block;
      margin: auto;
   }

   .empty-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding-top: 32px;

      &-title {
         margin: 24px 0;
         font-weight: 500;
         font-size: 24px;
         line-height: 34px;
         letter-spacing: -0.5px;
      }

      button {
         width: 142px;
         height: 40px;
      }
   }

   .list {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      padding: 20px 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #0C1024;
      border-radius: 12px;
      overflow-x: auto;
   }

   .table-pagination {
      margin-left: auto;
      margin-top: 20px;
      margin-right: 24px;
   }

   .table-loader {
      position: absolute;
      left: 50%;
      top: 50%;
   }

   .table-head {
      display: grid;
      grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(100px, 0.5fr) minmax(140px, 1fr) minmax(120px, 0.8fr);
      align-items: center;
      padding: 6px 24px;
      text-align: left;
      border-bottom: 1px solid rgba(255, 255, 255, 0.04);
      gap: 8px;

      p {
         font-weight: 500;
         font-size: 14px;
         line-height: 24px;
         letter-spacing: 0.17px;
         color: rgba(255, 255, 255, 0.5);
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }

   .table-body {
      display: grid;
      width: 100%;
   }

   @media (max-width: 500px) {
      padding: 38px 16px;
   }
}
