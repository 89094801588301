.domain-masking-add-wrapper {
   display: flex;
   flex-direction: column;
   flex: 1;
   padding: 48px;

   .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 34px;

      p {
         font-weight: 700;
         font-size: 32px;
         line-height: 44px;
         letter-spacing: -0.5px;

         @media (max-width: 500px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
         }
      }
   }

   .loader {
      display: block;
      margin: 160px auto;
   }

   .domain-masking-add-content {
      display: flex;
      gap: 24px;
   }

   .steps-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .step {
         width: 48px;
         height: 48px;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 64px;
         background: rgba(255, 255, 255, 0.08);
         font-size: 20px;
         font-style: normal;
         font-weight: 400;
         line-height: 28px;

         &.completed {
            background: rgba(23, 237, 195, 0.16);
         }

         &.waiting {
            background: rgba(0, 145, 255, 0.2);
         }

         &.error {
            background: rgba(240, 43, 79, 0.30);
         }
      }

      .step-line {
         width: 1px;
         background: linear-gradient(90deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.00) 100%);
      }
   }

   .form-wrapper {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: 600px;
      gap: 24px;
      padding: 40px 48px;
      border-radius: 12px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), #0A0E23;
   }

   .placeholder-input {
      & input {
        padding-top: 16px;
        padding-bottom: 16px;
      }
   }

   .input-with-label-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p:first-child {
         line-height: 24px;
      }
   }

   .next-button {
      width: 63px;
      height: 40px;
   }

   .what-to-do-next-wrapper {
      & > p:first-child {
         font-weight: 500;
         line-height: 24px;
         margin-bottom: 16px;
      }

      & > p:nth-child(2) {
         color: rgba(255, 255, 255, 0.70);
         font-weight: 400;
         line-height: 24px;
         letter-spacing: 0.15px;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .icon-wrapper {
         display: flex;
         align-items: center;
         justify-content: center;
         min-width: 48px;
         height: 48px;
         background: rgba(23, 237, 195, 0.16);
         border-radius: 64px;
      }

      .text-wrapper {
         & > p:first-child {
            font-weight: 500;
            line-height: 24px;
         }
   
         & > p:nth-child(2) {
            color: rgba(255, 255, 255, 0.70);
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.17px;
         }
      }
   }

   .complete-button {
      align-self: flex-end;
      width: 96px;
      height: 40px;
   }
  
    .more-error-info {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 2px;
      margin-right: 8px;
      cursor: pointer;
  
      p {
         width: 65px;
         color: #f8b0b4;
         font-size: 13px;
         font-style: normal;
         font-weight: 500;
         line-height: 22px;
         letter-spacing: 0.46px;
      }
    }

   @media (max-width: 500px) {
      padding: 38px 16px;
   }
}
