.tooltip {
  z-index: 1;
  font-weight: bold;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12));
  border-radius: 4px;
  max-width: 300px;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 16px;

    .popover-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .single-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .light-path {
      path {
        fill: #ffffff;
      }
    }

    .dark-path {
      path {
        fill: #242A33EB;
      }
    }
  }

  .description {
    padding: 10px 32px 10px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
  }
}

.popover-arrow,
.popover-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.popover-arrow {
  visibility: hidden;
}

.popover-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .popover-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .popover-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .popover-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .popover-arrow {
  left: -4px;
}
