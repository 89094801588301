.proxy-item-row-container {
   &:hover {
      background: rgba(0, 0, 0, 0.3);

      .item-actions {
         display: flex;
         gap: 4px;
      }
   }

   .item-row {
      display: grid;
      grid-template-columns: minmax(120px, 1fr) minmax(120px, 1fr) minmax(160px, 1fr) minmax(100px, 0.5fr) minmax(140px, 1fr) minmax(120px, 0.8fr);
      padding: 16px 24px;
      height: 72px;
      align-items: center;
      text-align: left;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      gap: 8px;
      
      p {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }

      .secondary-domain {
         display: flex;
         align-items: center;
         gap: 16px;

         .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 40px;
            width: 40px;
            height: 40px;
            border-radius: 64px;
            background: rgba(255, 255, 255, 0.3);

            &.completed {
               background: rgba(23, 237, 195, 0.16);
               path {
                  fill: #17EDC3;
               }
            }
   
            &.waiting {
               background: rgba(0, 145, 255, 0.2);
               path {
                  fill: #0091FF;
               }
            }
   
            &.error {
               background: rgba(240, 43, 79, 0.30);

               path {
                  fill: #F02B4F;
               }
            }
         }
      }

      .status-column {
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
   }
   
   .item-actions {
      display: none;
   }

   .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 4px;

      &:hover {
         background: rgba(255, 255, 255, 0.08);
         cursor: pointer;
      }
   }
}
